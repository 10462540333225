import styled, { css } from 'styled-components'
import { color, Media, spacing, transition, typography } from '../../../../common/theme'

export const PriceCalculatorContainer = styled.div`
  position: relative;
  margin: 60px auto 0;
  max-width: 1200px;
  background: #0d0c17;
  border-radius: 16px;
  padding: ${spacing.small} 120px 127px;
  color: white;
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.large} 0;
    padding: 0 ${spacing.base} 60px;
  }
`

export const PriceCalculatorTitle = styled.p`
  margin: 35px 0 27px;
  color: #7d829c;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.base} 0 ${spacing.small};
    font-size: ${typography.text};
  }
`

export const PriceCalculatorInputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const PriceCalculatorInputContainer = styled.div`
  position: relative;
  margin-left: ${p => (p.isRight ? '160px' : '90px')};
  ${Media.lessThan(Media.small)} {
    margin-left: 15px;
  }
`

export const CommonInput = css`
  width: 240px;
  height: 44px;
  margin: 0 5px;
  padding: 0 0.375em;
  line-height: 40px;
  border: 2px solid #504f5a80;
  border-radius: 6px;
  outline: none;
  transition: ${transition('border-color')};
  :focus {
    border-color: #ccc;
  }
  ${Media.lessThan(Media.small)} {
    width: 210px;
    margin-bottom: ${spacing.small};
  }
`

export const PriceCalculatorInput = styled.input`
  ${CommonInput}
`

export const PriceCalculatorSelect = styled.div`
  position: relative;
  display: inline-block;
  select {
    ${CommonInput};
    :not([multiple]):not([size]) {
      background-image: none;
    }
  }
  option {
    color: black;
  }
  ::after {
    content: '';
    position: absolute;
    top: 18px;
    right: 13px;
    border-top: 8px solid #7d829c;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`

export const PriceCalculatorBottomContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0%;
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: ${color.primary};
  font-weight: 500;
  font-size: 20px;
  background: #2d2c3b;
  ${Media.lessThan(Media.small)} {
    height: 40px;
    line-height: 40px;
    font-size: ${typography.text};
  }
`
