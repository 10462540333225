import React from 'react'
import { graphql } from 'gatsby'
import { Billboard, Button, Layout, SEO, SubmitForm } from '@dayancloud/common/components'
import {
  PriceBannerContainer,
  PriceBannerSubTitle,
  PriceBannerTitle,
  PriceCardContainer,
  PriceCardDescContainer,
  PriceCardSubTitle,
  PriceWrapperTitle,
  PriceCardWrapper,
  PriceCalculatorWrapper,
} from './price.atom'
import { PriceList } from '../constant/price'
import PriceCalculator from '../molecules/price/calculator'
import TryBanner from '../molecules/index/try-banner'
import ReleasePrice from '../molecules/price/release-price'
import PriceTableRow from '../molecules/price/price-table-row'

class Price extends React.PureComponent {
  render() {
    const {
      data,
      location,
      pageContext: { topFiveNews },
    } = this.props
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title='大雁云实景三维重建云服务-深圳市瑞云科技股份有限公司'
          keywords='大雁云实景三维重建平台,实景三维建模解决方案,无人机倾斜摄影建模'
          description='大雁云实景三维重建平台是瑞云科技推出的一款用户可自助便捷、快速将无人机倾斜摄影二维图片转为三维数据模型的工具，大雁云旨在为智慧城市、工程建设、乡村规划、应急测绘等行业赋能,打造云服务生态闭环。'
          ogUrl='https://www.dayancloud.com/price.html'
          ogType='website'
        />
        <PriceBannerContainer>
          <PriceBannerTitle>大雁云实景建模价格</PriceBannerTitle>
          <PriceBannerSubTitle>按量计费，建模成本比自购机器节省70%以上</PriceBannerSubTitle>
          <Button as='a' href='https://task.dayancloud.com/sso' rel='nofollow'>
            免费使用
          </Button>
          <Billboard fluid={data.banner.childImageSharp.fluid} />
        </PriceBannerContainer>
        <PriceCardWrapper>
          <PriceWrapperTitle>云重建价格</PriceWrapperTitle>
          <PriceCardSubTitle>适合大量建模需求，可累积,无时间限制</PriceCardSubTitle>
          <PriceCardContainer>
            {PriceList.map((info) => (
              <PriceTableRow key={info.title} info={info} />
            ))}
          </PriceCardContainer>
          <PriceCardDescContainer>
            <p>计费公式：（宽度 x 高度 x 照片数量）/ 1,000,000,000 x 重建价格</p>
            <h5>*GP是什么？</h5>
            <p>
              GP是指单个三维重建任务输入照片中像素总和，以十亿像素为单位,1GP(GigaPixel) =
              10亿像素，可以在价格页面查看详细价格。
            </p>
            <h5>*1GP能处理多少张图片?</h5>
            <p>
              1GP等于十亿像素，需要根据不同尺寸的照片进行计算，计算公式为：1GP处理量= 1,000,000,000
              / 照片宽度 / 照片高度。
            </p>
          </PriceCardDescContainer>
        </PriceCardWrapper>
        <PriceCalculatorWrapper>
          <PriceWrapperTitle>重建成本估算器</PriceWrapperTitle>
          <PriceCalculator />
        </PriceCalculatorWrapper>
        <ReleasePrice />
        <TryBanner />
        <SubmitForm />
      </Layout>
    )
  }
}

export default Price

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/price/price-banner.png/" }) {
      ...fluidImage
    }
  }
`
