import React from 'react'
import styled from 'styled-components'
import { Media } from '../../../../common/theme'

const ReleasePriceWrapper = styled.div`
  height: 399px;
  background-color: #0d0c17;
  color: white;
  ${Media.lessThan(Media.small)} {
    height: 243px;
  }
`
const ReleasePriceContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  h4 {
    font-size: 40px;
    font-weight: 500;
    color: #ffffff;
    ${Media.lessThan(Media.small)} {
      font-size: 20px;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 15px;
  }
`

const ReleasePrice = styled.div`
  height: 120px;
  background: #0d0c17;
  border: 1px solid #343343;
  border-radius: 8px;
  ${Media.lessThan(Media.small)} {
    height: 108px;
    border-radius: 4px;
  }
`

const ReleasePriceRow = styled.div`
  display: flex;
  .title {
    width: 260px;
    height: 59px;
    background: #1a1925;
    &.row1 {
      border-radius: 8px 0px 0px 0px;
      border-bottom: 1px solid #343343;
    }
    &.row2 {
      border-radius: 0px 0px 0px 8px;
    }
    span {
      line-height: 58px;
    }
    ${Media.lessThan(Media.small)} {
      height: 53px;
      &.row1 {
        border-radius: 4px 0px 0px 0px;
      }
      &.row2 {
        border-radius: 0px 0px 0px 4px;
      }
      span {
        line-height: 52px;
      }
    }
  }
  .price {
    width: 940px;
    &.row1 {
      border-bottom: 1px solid #343343;
    }
    span {
      line-height: 58px;
      color: #6b6cff;
      ${Media.lessThan(Media.small)} {
        line-height: 52px;
      }
    }
  }
`

class ReleaseCharge extends React.PureComponent {
  render() {
    return (
      <ReleasePriceWrapper>
        <ReleasePriceContainer>
          <h4>云发布价格收费</h4>
          <ReleasePrice>
            <ReleasePriceRow>
              <div className='title row1'>
                <span>起步价</span>
              </div>
              <div className='price row1'>
                <span>0.5h（半小时以内） 5元</span>
              </div>
            </ReleasePriceRow>
            <ReleasePriceRow>
              <div className='title row2'>
                <span>时长费</span>
              </div>
              <div className='price row2'>
                <span>1h / 10元</span>
              </div>
            </ReleasePriceRow>
          </ReleasePrice>
        </ReleasePriceContainer>
      </ReleasePriceWrapper>
    )
  }
}

export default ReleaseCharge
