import styled from 'styled-components'
import { Media, spacing, typography } from '../../../common/theme'

export const PriceBannerContainer = styled.div`
  position: relative;
  height: 600px;
  padding-top: 250px;
  z-index: 0;
  text-align: center;
  background: #ccc;
  color: #fff;
  ${Media.lessThan(Media.small)} {
    height: 300px;
    padding-top: 120px;
  }
`

export const PriceBannerTitle = styled.h1`
  margin: 0;
  font-size: 60px;
  line-height: 1;
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

export const PriceBannerSubTitle = styled.p`
  font-size: ${typography.h4};
  margin: 32px 0 32px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`

export const PriceCardWrapper = styled.div`
  padding-top: 90px;
  background: #0d0c17;
  ${Media.lessThan(Media.small)} {
    padding: 45px ${spacing.small} 0;
  }
`

export const PriceWrapperTitle = styled.h2`
  margin: 0;
  font-size: ${typography.title};
  line-height: 1;
  font-weight: 500;
  text-align: center;
  color: white;
  ${Media.lessThan(Media.small)} {
    font-size: 20px;
  }
`

export const PriceCardSubTitle = styled.p`
  margin: 26px 0 67px;
  text-align: center;
  color: #7d829c;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    margin: 13px 0 ${spacing.large};
    font-size: ${typography.textThin};
  }
`

export const PriceCardContainer = styled.div`
  max-width: 1200px;
  margin: auto;
`

export const PriceCardDescContainer = styled.div`
  padding: 60px 0 ${spacing.large};
  margin: auto;
  max-width: 1200px;
  h5 {
    margin: 0 0 15px;
    font-size: ${typography.text};
    color: white;
  }
  p {
    margin: 0 0 ${spacing.large};
    color: #7d829c;
    font-size: ${typography.textSmall};
  }
  ${Media.lessThan(Media.small)} {
    padding: ${spacing.large} 0;
    h5,
    p {
      font-size: ${typography.textThin};
    }
  }
`

export const PriceCalculatorWrapper = styled.div`
  padding: 90px 0 110px;
  background: #1a1925;
  ${Media.lessThan(Media.small)} {
    padding: 45px ${spacing.small};
  }
`
