import { Button, Flex } from '@dayancloud/common/components'
import { color } from '@dayancloud/common/theme'
import React from 'react'
import {
  CellContainer,
  CellTips,
  CellTitle,
  MobileButton,
  MobileContainer,
  PriceMobileBody,
  PriceMobileHeader,
  RechargeCell,
  RowContainer,
} from './atom'

export default class PriceTableRow extends React.PureComponent {
  render() {
    const { icon, title, trigger, blockPrice, redevelopmentPrice } = this.props.info
    return (
      <>
        <RowContainer>
          <CellContainer width='260px'>
            <div>
              <img src={icon} alt={title} />
              <CellTitle>{title}</CellTitle>
            </div>
          </CellContainer>
          <CellContainer>
            <Flex
              style={{ height: '50px', width: '100%', paddingLeft: '30px' }}
              direction='column'
              valign='space-between'
              halign='flex-start'
            >
              <CellTips>充值金额</CellTips>
              <p>{trigger}</p>
            </Flex>
          </CellContainer>
          <CellContainer>
            <Flex
              style={{ height: '50px', width: '100%', paddingLeft: '30px' }}
              direction='column'
              valign='space-between'
              halign='flex-start'
            >
              <p>空三</p>
              <p style={{ color: color.primary }}>￥{blockPrice.toFixed(2)}元/GP</p>
            </Flex>
          </CellContainer>
          <CellContainer width='400px'>
            <RechargeCell>
              <Flex
                direction='column'
                style={{ height: '50px' }}
                valign='space-between'
                halign='flex-start'
              >
                <p>重建</p>
                <p style={{ color: color.primary }}>￥{redevelopmentPrice.toFixed(2)}元/GP</p>
              </Flex>
              <Button
                backgroundColor={color.primary}
                as='a'
                href='https://account.dayancloud.com/user/topUp'
                rel='nofollow'
              >
                立即充值
              </Button>
            </RechargeCell>
          </CellContainer>
        </RowContainer>
        <MobileContainer>
          <PriceMobileHeader>
            <img src={icon} alt={title} />
            <span>{title}</span>
          </PriceMobileHeader>
          <PriceMobileBody>
            <span>{trigger}</span>
            <Flex halign='space-between'>
              <span>空三</span>
              <span style={{ color: color.primary }}>￥{blockPrice.toFixed(2)}元/GP</span>
            </Flex>
            <Flex halign='space-between'>
              <span>重建</span>
              <span style={{ color: color.primary }}>￥{redevelopmentPrice.toFixed(2)}元/GP</span>
            </Flex>
            <MobileButton>立即充值</MobileButton>
          </PriceMobileBody>
        </MobileContainer>
      </>
    )
  }
}
