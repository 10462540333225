import OrdinaryIcon from '../../images/price/vip-icons/ordinary.png'
import SilverIcon from '../../images/price/vip-icons/silver.png'
import GoldIcon from '../../images/price/vip-icons/gold.png'
import PlatinumIcon from '../../images/price/vip-icons/platinum.png'
import DiamondIcon from '../../images/price/vip-icons/diamond.png'

export const PriceRank = Object.freeze({
  Ordinary: Symbol('ordinary'),
  Silver: Symbol('silver'),
  Gold: Symbol('Gold'),
  Platinum: Symbol('platinum'),
  Diamond: Symbol('diamond'),
})

const rankIconMap = new Map([
  [PriceRank.Ordinary, OrdinaryIcon],
  [PriceRank.Silver, SilverIcon],
  [PriceRank.Gold, GoldIcon],
  [PriceRank.Platinum, PlatinumIcon],
  [PriceRank.Diamond, DiamondIcon],
])
const rankTitleMap = new Map([
  [PriceRank.Ordinary, '普通会员'],
  [PriceRank.Silver, '白银会员'],
  [PriceRank.Gold, '黄金会员'],
  [PriceRank.Platinum, '铂金会员'],
  [PriceRank.Diamond, '钻石会员'],
])
const rankBlockPriceMap = new Map([
  [PriceRank.Ordinary, 0.9],
  [PriceRank.Silver, 0.8],
  [PriceRank.Gold, 0.7],
  [PriceRank.Platinum, 0.6],
  [PriceRank.Diamond, 0.5],
])
const rankRedevelopmentPriceMap = new Map([
  [PriceRank.Ordinary, 4],
  [PriceRank.Silver, 3.5],
  [PriceRank.Gold, 3],
  [PriceRank.Platinum, 2.5],
  [PriceRank.Diamond, 2],
])
const rankTriggerMap = new Map([
  [PriceRank.Ordinary, '累计充值<1000元'],
  [PriceRank.Silver, '累计充值满1000元'],
  [PriceRank.Gold, '累计充值满3000元'],
  [PriceRank.Platinum, '累计充值满10000元'],
  [PriceRank.Diamond, '累计充值满20000元'],
])

export const PriceList = Object.values(PriceRank).map(value => ({
  icon: rankIconMap.get(value),
  title: rankTitleMap.get(value),
  blockPrice: rankBlockPriceMap.get(value),
  redevelopmentPrice: rankRedevelopmentPriceMap.get(value),
  trigger: rankTriggerMap.get(value),
}))
