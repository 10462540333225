import { color, hideSMDown, Media, spacing, transition, typography } from '@dayancloud/common/theme'
import styled from 'styled-components'

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #343343;
  background-color: transparent;
  transition: ${transition('background-color')};
  color: white;
  font-weight: 500;
  :hover {
    background-color: #171620;
  }
  :not(:first-of-type) {
    border-top: none;
  }
  p {
    margin: 0;
    line-height: 1;
  }
  div {
    border-color: #343343;
  }
  ${hideSMDown};
`

export const CellContainer = styled.div`
  width: ${p => p.width || '100%'};
  flex-shrink: ${p => (p.width ? 0 : 1)};
  margin: ${spacing.base} 0;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  :not(:first-of-type, :last-of-type) {
    border-right-width: 1px;
    border-right-style: solid;
  }
  :first-of-type {
    background: #171620;
    margin: 0;
    height: 120px;
  }
`

export const CellTitle = styled.h5`
  margin: 0 0 0 28px;
  display: inline-block;
  font-size: ${typography.h3};
  vertical-align: top;
`

export const CellTips = styled.p`
  font-size: ${typography.textSmall};
  color: #999;
  font-weight: 400;
`

export const RechargeCell = styled.div`
  width: 100%;
  padding: 0 60px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PriceMobileHeader = styled.div`
  padding: 0 ${spacing.large};
  height: 55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #1a1925;
  font-size: ${typography.h4};
  font-weight: 500;
  color: white;
  img {
    width: 23px;
    margin-right: 10px;
  }
`

export const PriceMobileBody = styled.div`
  margin-bottom: 15px;
  padding: ${spacing.small} ${spacing.large} 16px ${spacing.base};
  border: 1px solid #2f2e39;
  border-top: none;
  line-height: 30px;
  font-size: ${typography.text};
  font-weight: 500;
  color: white;
  ::after {
    content: '';
    display: block;
    clear: both;
  }
`

export const MobileContainer = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`

export const MobileButton = styled.button`
  margin-top: ${spacing.small};
  width: 120px;
  height: 35px;
  background: ${color.primary};
  border-radius: 4px;
  font-size: ${typography.text};
  font-weight: 400;
  border: none;
  float: right;
`
