import React, { useEffect, useState } from 'react'
import { PriceList } from '../../constant/price'
import {
  PriceCalculatorBottomContainer,
  PriceCalculatorContainer,
  PriceCalculatorInput,
  PriceCalculatorInputContainer,
  PriceCalculatorInputWrapper,
  PriceCalculatorSelect,
  PriceCalculatorTitle,
} from './calculator.atom'

const Billion = 1000000000

const ModelType = Object.freeze({
  block: '0',
  redevelopment: '1',
  all: '2',
})

const ModelList = [
  {
    type: ModelType.block,
    label: '空三',
  },
  {
    type: ModelType.redevelopment,
    label: '重建',
  },
  {
    type: ModelType.all,
    label: '空三 + 重建',
  },
]

const PriceCalculator = React.memo(function() {
  const [price, setPrice] = useState(0)
  const [formData, setFormData] = useState({
    width: '1000',
    height: '1000',
    num: '1000',
    rank: '0',
    model: ModelType.all,
  })

  function getUnitPrice(model = ModelType.all, rank = '0') {
    switch (model) {
      case ModelType.block: {
        return PriceList[rank].blockPrice
      }
      case ModelType.redevelopment: {
        return PriceList[rank].redevelopmentPrice
      }
      default: {
        return PriceList[rank].blockPrice + PriceList[rank].redevelopmentPrice
      }
    }
  }

  useEffect(() => {
    const widthNum = Number(formData.width) || 0
    const heightNum = Number(formData.height) || 0
    const numNum = Number(formData.num) || 0
    const unitPrice = getUnitPrice(formData.model, formData.rank)
    const currentPrice = (unitPrice * (widthNum * heightNum * numNum)) / Billion
    setPrice(currentPrice.toFixed(3))
  }, [formData])

  function handleChangeInput(name, value) {
    if (typeof value !== 'string' || value.match(/^\d*$/)) {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }
  return (
    <PriceCalculatorContainer>
      <PriceCalculatorTitle>您的照片分辨率</PriceCalculatorTitle>
      <PriceCalculatorInputWrapper>
        <PriceCalculatorInputContainer>
          宽度：
          <PriceCalculatorInput
            value={formData.width}
            onChange={e => {
              handleChangeInput('width', e.target.value)
            }}
          />
          px
        </PriceCalculatorInputContainer>
        <PriceCalculatorInputContainer isRight>
          高度：
          <PriceCalculatorInput
            value={formData.height}
            onChange={e => {
              handleChangeInput('height', e.target.value)
            }}
          />
          px
        </PriceCalculatorInputContainer>
      </PriceCalculatorInputWrapper>
      <PriceCalculatorTitle>您的照片数量</PriceCalculatorTitle>
      <PriceCalculatorInputWrapper>
        <PriceCalculatorInputContainer>
          数量：
          <PriceCalculatorInput
            value={formData.num}
            onChange={e => {
              handleChangeInput('num', e.target.value)
            }}
          />
          张
        </PriceCalculatorInputContainer>
      </PriceCalculatorInputWrapper>
      <PriceCalculatorTitle>您的会员级别</PriceCalculatorTitle>
      <PriceCalculatorInputWrapper>
        <PriceCalculatorInputContainer>
          级别：
          <PriceCalculatorSelect>
            <select
              value={formData.rank}
              onChange={e => {
                handleChangeInput('rank', e.target.value)
              }}
            >
              {PriceList.map((info, index) => (
                <option key={index} value={index}>
                  {info.title}
                </option>
              ))}
            </select>
          </PriceCalculatorSelect>
        </PriceCalculatorInputContainer>
      </PriceCalculatorInputWrapper>
      <PriceCalculatorTitle>您的计算模式</PriceCalculatorTitle>
      <PriceCalculatorInputWrapper>
        <PriceCalculatorInputContainer>
          模式：
          <PriceCalculatorSelect>
            <select
              value={formData.model}
              onChange={e => {
                handleChangeInput('model', e.target.value)
              }}
            >
              {ModelList.map(info => (
                <option key={info.type} value={info.type}>
                  {info.label}
                </option>
              ))}
            </select>
          </PriceCalculatorSelect>
        </PriceCalculatorInputContainer>
      </PriceCalculatorInputWrapper>
      <PriceCalculatorBottomContainer>
        计算结果：您的费用是 {price} 元
      </PriceCalculatorBottomContainer>
    </PriceCalculatorContainer>
  )
})

export default PriceCalculator
